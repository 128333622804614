import React from 'react';
import { Box, verticalSpace } from 'design-system';
import { IconLink } from 'components/elements';
import { Link } from 'components/modules';
import { BreadcrumbArrowLargeIcon, BreadcrumbArrowSmallIcon } from 'assets/svg';
import { withWindowWidth } from 'utils';

const BaseBlogBreadcrumb = ({
  activeBreakpoint,
  url = '/blog/',
  text = 'Blog',
}) => (
  <Box
    display="flex"
    alignItems="center"
  >
    <Link linkConfig={{ props: { url } }} type="Medium" hideArrow>
      <Box mr={verticalSpace.xSmall} display="inline-block">
        ←
      </Box>
      {text}
    </Link>
  </Box>
);

export const BlogBreadcrumb = withWindowWidth(BaseBlogBreadcrumb);
