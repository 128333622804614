import { graphql, useStaticQuery } from 'gatsby';

export const contentfulDataFormatter = (data, treeName) => {
  const [blogSettingsData] = data[treeName].edges;
  return {
    ...JSON.parse(blogSettingsData.node.componentTree),
  };
};

export const getBlogSettings = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogSettingsTree {
        edges {
          node {
            componentTree
          }
        }
      }
    }
  `);

  return contentfulDataFormatter(data, 'allContentfulBlogSettingsTree');
};

export const liftNode = ({ node }) => node;

export const isBlogRouteActive = (currentRoute, routeToCompare) => {
  const currentRouteWithTrailingSlash =
    currentRoute.substring(currentRoute.length - 1) !== '/'
      ? `${currentRoute}/`
      : currentRoute;
  return (
    currentRouteWithTrailingSlash
      .replace(`/blog`, '')
      .replace(/\/page\/[0-9]/g, '') === routeToCompare
  );
};
