import * as cheerio from 'cheerio';

// pluralize
const plural = (readTime) => {
  return readTime > 1 ? 's' : '';
};

// cheerio for excerpts/summary
export const summarySelect = (postHtml, plaintext) => {
  // reading time calculation
  const numberWords = plaintext.trim().split(/\s+/).length;
  const readingTime = Math.ceil(numberWords / 265);

  const $ = cheerio.load(postHtml);

  // remove images and breaks
  $('img').remove();
  $('br').remove();

  // find first p tag that has enough text
  for (let i = 1; i <= $('p').length; i++) {
    const paragraphNumber = `p:nth-of-type(${i})`;
    const paragraphHTML = $(paragraphNumber).html();

    if (paragraphHTML == null) {
      return '';
    }
    if (paragraphHTML.length > 100) {
      return $(paragraphNumber)
        .append(
          `<br><br>Reading time: ${readingTime} minute${plural(readingTime)}`,
        )
        .html();
    }
  }

  return '';
};
