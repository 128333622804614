import _flow from 'lodash/flow';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _flattenDeep from 'lodash/flattenDeep';
import {
  filterApplicators,
  nodeNormalizers,
  paginateNodes,
  normalizeNodesForRender,
  sortNodes,
} from 'utils';

const filterPlaceholder = () => () => false;

const filterContentByCriteria = (applicators) => (filters = []) => (
  itemsByType,
) => {
  if (!filters.length) {
    return itemsByType;
  }

  return Object.entries(itemsByType).reduce(
    (result, [contentType, contentItems]) => {
      const filtersToUse = filters.map(({ type, value }) =>
        // if we have an empty array, we are in the "all" case
        value.length === 0
          ? () => true
          : _get(applicators, `${contentType}.${type}`, filterPlaceholder)(
              value,
            ),
      );

      const filtersPipeline = (itemToTest) =>
        filtersToUse.every((filterToTest) => filterToTest(itemToTest));

      return {
        ...result,
        [contentType]: contentItems.filter(filtersPipeline),
      };
    },
    {},
  );
};

const mergeNodesFromContentTypes = (nodes) =>
  _flattenDeep(Object.values(nodes));

const filterCurrentSlugFromNodes = (currentSlug) => (nodes) =>
  nodes.filter(({ slug }) => slug !== currentSlug);

const nodeNormalizeContent = (items) =>
  Object.entries(items).reduce(
    (result, [contentType, contentItems]) => ({
      ...result,
      [contentType]: contentItems.map(nodeNormalizers[contentType]),
    }),
    {},
  );

const DEFAULT_CONTENT_TYPES = ['podcast', 'blog'];

export const getContentByCriteria = (
  {
    contentSlug,
    filters: filtersParam = [],
    pagination = {
      perPage: 3,
      currentPage: 1,
    },
  },
  data,
) => {
  const queryData = data;

  const { allGhostPost, allContentfulPodcastTree } = queryData;
  const itemsDict = {
    podcast: allContentfulPodcastTree.edges.map(({ node }) =>
      JSON.parse(node.componentTree),
    ),
    blog: allGhostPost ? allGhostPost.edges.map(({ node }) => node) : [],
  };

  const contentTypesFilter = filtersParam.find(
    ({ type }) => type === 'contentTypes',
  );
  const contentTypes = contentTypesFilter
    ? contentTypesFilter.value
    : DEFAULT_CONTENT_TYPES;

  const itemsToProcess = contentTypes.length
    ? _pick(itemsDict, contentTypes)
    : itemsDict;

  const filters = filtersParam.filter(({ type }) => type !== 'contentTypes');

  return _flow(
    filterContentByCriteria(filterApplicators)(filters),
    nodeNormalizeContent,
    mergeNodesFromContentTypes,
    filterCurrentSlugFromNodes(contentSlug),
    sortNodes,
    paginateNodes(pagination),
    normalizeNodesForRender,
  )(itemsToProcess);
};
