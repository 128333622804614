/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Box, List, BulletListItem, verticalSpace } from 'design-system';
import { Text } from 'components';

// Algolia
import { connectInfiniteHits, Highlight } from 'react-instantsearch-dom';

import { Link } from '../Link';

// Determine proper working slug to link to, given the index type
// TODO: Move this to a utils file
const getHitSlug = (index, slug) => {
  const hyphenatedSlug = slug.replace(/,| |\(|\)/g, (char) =>
    char === ' ' ? '-' : '',
  );
  const finalSlug =
    hyphenatedSlug.substr(0, 1) !== '/'
      ? hyphenatedSlug
      : hyphenatedSlug.substr(1);

  switch (index) {
    case 'Page':
    case 'dev_Page':
      return finalSlug;
    case 'Podcast':
    case 'dev_Podcast':
      return finalSlug.includes('podcasts/')
        ? finalSlug
        : `podcasts/${finalSlug}`;
    case 'Blog':
    case 'dev_Blog':
      return finalSlug.includes('blog/') ? finalSlug : `blog/${finalSlug}`;
    case 'Career':
    case 'dev_Career':
      return finalSlug.includes('careers/')
        ? finalSlug
        : `careers/${finalSlug}`;
    default:
      return finalSlug;
  }
};

// Custom Hit component to link to individual internal search results
const Hit = ({ hit }) => {
  const searchTerm = document.querySelector('#global-search').value;
  const linkConfig = {
    props: {
      url: `/${getHitSlug(hit.index, hit.slug)}`,
      analyticsLabel: `Search | ${searchTerm}`,
    },
  };

  console.log(linkConfig);

  return (
    <BulletListItem
      pt={verticalSpace.xSmall}
      pb={verticalSpace.xSmall}
      key={getHitSlug(hit.index, hit.slug)}
    >
      <Link type="Medium" theme="dark" linkConfig={linkConfig}>
        <Highlight attribute="title" hit={hit} />
      </Link>
      <Text type="Small Body" color="text.3">
        <Highlight attribute="description" hit={hit} />
      </Text>
      <Text type="Legal" color="text.3">
        <Highlight attribute="tags" hit={hit} />
      </Text>
    </BulletListItem>
  );
};

const InfiniteHits = ({ hits, hasMore, refineNext, index }) => {
  return (
    hits.length > 0 && (
      <Box>
        <List>
          {hits.map((hit) => (
            <Hit key={hit.objectId} index={index} hit={{ ...hit, index }} />
          ))}
        </List>
        {/* Implement the load more button later after amalgamating indices
         * For now, omit this feature, and also omit `hitsPerPage` prop on Configure component
         */}
        {/* hasMore && (
          <Box mt={verticalSpace.small} mb={verticalSpace.medium}>
            <Link
              hideArrow
              type="Secondary Button"
              theme="dark"
              linkConfig={{ props: { url: '#' } }}
              onClick={refineNext}
            >
              See more +
            </Link>
          </Box>
        ) */}
      </Box>
    )
  );
};

export const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);
