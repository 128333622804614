import React from 'react';
import _chunk from 'lodash/chunk';
import { BlogCard, BlogCardResponsiveImgSet } from 'components';
import { Box, Column, Row, theme, Image } from 'design-system';

const CardToUse = ({
  post: {
    title,
    slug,
    published_at_pretty: datePublished,
    feature_image: featureImage,
    authors,
  },
  isFeatured,
  numberOfCards,
}) => {
  const imageProps = {
    altText: title,
    imageDesktop: <Image src={featureImage} />,
  };
  return (
    <BlogCard
      title={title}
      linkTitle={title}
      cta={title}
      numberOfCards={numberOfCards}
      datePublished={datePublished}
      featured={isFeatured}
      linkUrl={`/blog/${slug}`}
      authorName={authors.map(({ name }) => name).join(', ')}
      image={<BlogCardResponsiveImgSet {...imageProps} />}
    />
  );
};

const FeaturedCardWithWrapper = ({ post }) => (
  <Column
    width={[1, 1 / 2]}
    display="flex"
    flexDirection="column"
    pb={[0, theme.verticalSpace.medium, theme.verticalSpace.medium]}
  >
    <CardToUse post={post} isFeatured />
  </Column>
);

const UnfeaturedCardsWithWrapper = ({ posts }) => (
  <Box width={[1, 1 / 2]}>
    {posts.map((post) => (
      <CardToUse
        key={`blog-card-slugged-${post.slug}`}
        post={post}
        numberOfCards={posts.length}
      />
    ))}
  </Box>
);

export const BlogPostGrid = ({ posts }) => {
  const numberOfCards = posts.length;
  const chunkedPosts = _chunk(posts, 3);
  return (
    <div>
      {chunkedPosts.map((chunk, chunkIndex) => {
        const featuredLayoutPosition = chunkIndex % 2 === 0 ? 'left' : 'right';
        const featuredPost =
          featuredLayoutPosition === 'left' ? chunk[0] : chunk[2];
        const unfeaturedPosts =
          featuredLayoutPosition === 'left'
            ? chunk.slice(1, 3)
            : chunk.slice(0, 2);

        return (
          <Row key={chunk} mb={[0, '40px']}>
            {featuredPost && featuredLayoutPosition === 'left' && (
              <FeaturedCardWithWrapper
                post={featuredPost}
                numberOfCards={numberOfCards}
              />
            )}
            {unfeaturedPosts.length !== 0 && (
              <UnfeaturedCardsWithWrapper
                posts={unfeaturedPosts}
                numberOfCards={numberOfCards}
              />
            )}
            {featuredPost && featuredLayoutPosition === 'right' && (
              <FeaturedCardWithWrapper
                post={featuredPost}
                numberOfCards={numberOfCards}
              />
            )}
          </Row>
        );
      })}
    </div>
  );
};
