/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import get from 'lodash/get';
import { theme, Box, horizontalSpace } from 'design-system';
import { Link, SocialIcon, Text, Spacing, Grid, PageHelmet } from 'components';
import htmlParser from './htmlParser';
import { StyledList } from '../../../patterns/Markdown';

class CareersDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: 'https://rangle.io/careers',
    };
  }

  componentDidMount() {
    if (window) {
      this.setState({ url: window.location.href });
    }
  }

  render() {
    const {
      buildContext: { leverBuildContext, postingId },
    } = this.props;
    const { url } = this.state;
    {
      const href = get(leverBuildContext, ['postings', postingId, 'applyUrl']);
      const description = get(leverBuildContext, [
        'postings',
        postingId,
        'description',
      ]);
      const descriptionPlain = get(leverBuildContext, [
        'postings',
        postingId,
        'descriptionPlain',
      ]);
      const lists = get(
        leverBuildContext,
        ['postings', postingId, 'lists'],
        [],
      );
      const additional = get(leverBuildContext, [
        'postings',
        postingId,
        'additional',
      ]);
      const summaryLength = descriptionPlain
        ? descriptionPlain.split('\n')[0].split(' ').length
        : '';
      const queryString = {
        url,
        mini: true,
        title: postingId,
        source: 'Rangle',
        summary: summaryLength > 2 ? descriptionPlain.split('\n')[0] : '',
      };

      const linkConfig = { props: { url: href } };

      const socialIconProps = [
        {
          id: 'Twitter',
          platform: 'Twitter',
          linkConfig: {
            props: {
              url: `https://twitter.com/intent/tweet?url=${
                queryString.url
              }&text=${queryString.summary}`,
            },
          },
        },
        {
          id: 'LinkedIn',
          platform: 'LinkedIn',
          linkConfig: {
            props: {
              url: `https://www.linkedin.com/shareArticle?mini=${
                queryString.mini
              }&url=${queryString.url}&title=${queryString.title}&summary=${
                queryString.summary
              }&source=${queryString.source}`,
            },
          },
        },
        {
          id: 'Facebook',
          platform: 'Facebook',
          linkConfig: {
            props: {
              url: `https://www.facebook.com/sharer.php?u${queryString.url}`,
            },
          },
        },
      ];
      const title = leverBuildContext.postings[postingId].text;
      const categories = ['Specialty', 'Location', 'Duration'];
      const subHeadingsText = categories.reduce((acc, category) => {
        const caption = get(leverBuildContext, [
          'postings',
          postingId,
          category.toLowerCase(),
        ]);

        acc.push({
          category,
          caption,
        });
        return acc;
      }, []);

      const subHeadingBlocks = subHeadingsText.map((cur) => (
        // eslint-disable-next-line react/jsx-key
        <Box borderTop={`1px solid ${theme.colors.uiDivider}`} flex="1">
          <Spacing height="xxSmall" />
          <Text type="Medium Body Dark">{cur.category}</Text>
          <Text type="Medium Body">{cur.caption}</Text>
        </Box>
      ));

      const mainContent = (
        <Box>
          <Box>{htmlParser(description)}</Box>
          <Spacing height="large" />
          <Box
            display={['block', 'block', 'none']}
            mb={theme.verticalSpace.large}
          >
            <Link type="Button" linkConfig={linkConfig}>
              Apply Now
            </Link>
          </Box>
          {lists.map((list, key, index) => (
            <>
              <Box
                // TODO: get a real key in here
                // eslint-disable-next-line react/no-array-index-key
                key={key}
              >
                <Text type="Medium Heading">{list.text}</Text>
                <Spacing height="xSmall" />
                <StyledList listStyleType="disc" ml="11px" pl="8px">
                  {htmlParser(list.content)}
                </StyledList>
              </Box>
              {index !== lists.length - 1 && <Spacing height="large" />}
            </>
          ))}
          {additional && <Box>{htmlParser(additional)}</Box>}
          {socialIconProps ? (
            <>
              <Spacing height="medium" />
              <Text type="Small Heading">Share</Text>
              <Spacing height="small" />
              <Box display="flex" width="100px" justifyContent="space-between">
                {socialIconProps.map((socialIconProp) => (
                  <SocialIcon
                    key={`social-media-share-link-id-${socialIconProp.id}`}
                    mr="medium"
                    {...socialIconProp}
                  />
                ))}
              </Box>
            </>
          ) : null}
        </Box>
      );

      const callout = (
        <Box
          bg="lightBlue"
          position="relative"
          dataQaid="CareerDetailCallout"
          alignSelf="flex-start"
          px={horizontalSpace.small}
        >
          <Spacing height="small" />
          <Text type="Medium Body Dark">
            Don't currently tick all the boxes?
          </Text>
          <Spacing height="xSmall" />
          <Text type="Medium Body">
            We’re a culture of growth. If you're excited about this role, have
            most of the skills, and can learn the rest, please apply!
          </Text>
          <Spacing height="xSmall" />
          <Link type="Button" linkConfig={linkConfig}>
            Apply Now
          </Link>
          <Spacing height="small" />
        </Box>
      );

      return (
        <Box px={horizontalSpace.layout}>
          <PageHelmet
            title={title}
            description={description}
            image="https://res.cloudinary.com/rangle/image/upload/q_auto,f_auto/rangle.io/see5uevo9r9mioxwqjy4.png"
          />
          <Spacing height="xLarge" />
          <Text type="Large Heading">{title}</Text>
          <Spacing height="large" />
          <Grid
            cells={subHeadingBlocks}
            columnRatio="1:1:1"
            gutters="medium"
            cellBottomPadding="medium"
          />
          <Spacing height="large" />
          <Grid
            cells={[mainContent, callout]}
            gutters="large"
            cellBottomPadding="medium"
            columnRatio="5:3"
            columnRatioTablet="1"
          />
        </Box>
      );
    }
  }
}

export {
  CareersDetail,
  CareersDetail as JobPostingsDetail, // Downward compatibility.
};
