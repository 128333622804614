import React from 'react';
import { Box, Column, Row, Text, theme } from 'design-system';

let prevSpeakerLabel = null;
const removeConsecutiveSpeakerLabelsReducer = (
  rowsOfColumnsResult,
  currentRow,
) => {
  const [, { speakerName: previousRowSpeaker }] = rowsOfColumnsResult[
    rowsOfColumnsResult.length - 1
  ] || [
    '',
    {
      speakerName: null,
    },
  ];
  prevSpeakerLabel =
    previousRowSpeaker !== null ? previousRowSpeaker : prevSpeakerLabel;
  const [currentCol1, currentCol2] = currentRow;
  const { speakerName: currentSpeaker, ...restCurrentCol2 } = currentCol2;
  const hasNewSpeaker = prevSpeakerLabel !== currentSpeaker;
  const newRow = [
    currentCol1,
    {
      ...restCurrentCol2,
      speakerName: hasNewSpeaker ? currentSpeaker : null,
      hasNewSpeaker,
    },
  ];
  return [...rowsOfColumnsResult, newRow];
};

const makeTranscriptRowColumns = (transcript) => {
  const rows = transcript.split(/\n/).filter((row) => row.match(/\s+/));
  // the expected pattern is:
  // Interviewer:     12:34      Transcription string lorem ipsum cit dolor
  const rowsOfColumns = rows.map((row) => {
    const [speakerName, timestamp, transcription] = row
      .split(/(.+:)\s+([0-9]+:[0-9]+)\s+(.*)/g)
      .filter((column) => column !== '');
    return [timestamp, { speakerName, transcription }];
  });
  // If the same speaker is on two or more timestamps in a row,
  // don't show the speaker's name after the first one
  return rowsOfColumns.reduce(removeConsecutiveSpeakerLabelsReducer, []);
};

const columnStylesDict = [
  {
    columnWidths: [1, 1 / 6, 1 / 8, 1 / 10],
    textStyles: {
      ...theme.typography.light.body1,
      fontSize: ['bodyS', 'bodyS', 'bodyS', 'bodyM'],
      // old [1, 1, 1, 2]
      lineHeight: [3, 3, 3, 4],
      color: 'text.0',
    },
  },
  {
    columnWidths: [1, 5 / 6, 7 / 8, 9 / 10],
    textStyles: {
      ...theme.typography.light.body1,
      fontSize: ['bodyS', 'bodyS', 'bodyS', 'bodyM'],
      // old [1, 1, 1, 2]
      lineHeight: [3, 3, 3, 4],
      color: 'text.0',
    },
  },
];

const transcriptTable = (rowsOfColumnsOfTranscript) =>
  rowsOfColumnsOfTranscript.map((row, rowIndex) => (
    <Row
      key={`transcript-timestamp-${row[0]}-speaker-${row[1].speakerName}`}
      py={theme.verticalSpace.xSmall}
      mx={theme.gutters.map((gutter) => gutter * -1)}
      borderTop={rowIndex !== 0 && row[1].hasNewSpeaker ? 1 : 0}
      alignItems="start"
    >
      <Column
        width={columnStylesDict[0].columnWidths}
        display={[row[1].hasNewSpeaker ? 'block' : 'none', 'block']}
      >
        <Text {...columnStylesDict[0].textStyles}>{row[0]}</Text>
      </Column>
      <Column width={columnStylesDict[1].columnWidths}>
        <Text {...columnStylesDict[1].textStyles}>
          {!!row[1].speakerName && (
            <strong style={{ fontFamily: theme.fonts.regular }}>
              {row[1].speakerName}
            </strong>
          )}{' '}
          {row[1].transcription}
        </Text>
      </Column>
    </Row>
  ));

const reprocessTranscript = (transcript) => {
  const rowsOfColumnsOfTranscript = makeTranscriptRowColumns(transcript);
  return transcriptTable(rowsOfColumnsOfTranscript);
};

export const PodcastTranscript = ({ transcript }) => (
  <Box>{reprocessTranscript(transcript)}</Box>
);
