import React from 'react';
import { theme, Box } from 'design-system';
import { CopyMediaGroup, MediumHeading, PodcastLink } from 'components';
import { getSiteSettings } from 'utils';

export const SidebarList = ({
  withCopyMediaGroup,
  seasonDescription,
  seasonHeading,
  showHeading,
  podcastLinkSpecs,
}) => {
  const siteSettings = getSiteSettings();
  const otherEpisodesHeading =
    siteSettings.props.podcastSettings.props
      .podcastEpisodeOtherEpisodesHeading || 'Other Episodes in This Season';
  return (
    <>
      {withCopyMediaGroup && (
        <Box
          mx={theme.gutters.map((gutter) => gutter * -1)}
          mb={[theme.verticalSpace.small, theme.verticalSpace.medium]}
        >
          <CopyMediaGroup
            paragraph={seasonDescription}
            heading={seasonHeading}
          />
        </Box>
      )}
      {showHeading && (
        <Box mb={[theme.verticalSpace.medium]}>
          <MediumHeading text={otherEpisodesHeading} />
        </Box>
      )}
      {podcastLinkSpecs.map((podcastLinkSpec) => (
        <PodcastLink
          key={`podcast-link-slug-${podcastLinkSpec.slug}`}
          {...podcastLinkSpec}
        />
      ))}
    </>
  );
};
