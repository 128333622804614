/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { theme, Box, Link } from 'design-system';
import { withLocation } from 'utils';
import { DropdownArrowIcon } from 'assets/svg';

const BlogNavHorizontalMenu = ({ navItems }) => (
  <Box
    display={['none', 'none', 'none', 'flex']}
    alignItems="center"
    overflowY="hidden"
    letterSpacing="0.4px"
    whiteSpace="nowrap"
  >
    <ul className="nav" role="menu">
      {navItems.map(({ label, url, isCurrent }) => {
        return (
          <li
            key={`blog-flat-nav-tag-${url}`}
            className={`nav-all-posts ${isCurrent ? 'nav-current' : ''}`}
            role="menuitem"
          >
            <Link
              fontFamily={isCurrent ? 'regular' : 'light'}
              href={`/blog${url}`}
              colors="lightBackgroundNavItem"
              className={`${isCurrent ? 'active' : ''}`}
            >
              {label}
            </Link>
          </li>
        );
      })}
    </ul>
  </Box>
);

class BlogNavDropdownMenu extends Component {
  handleOnChange = (event) => {
    const { navigate } = this.props;
    navigate(event.target.value);
  };

  render() {
    const { navItems } = this.props;
    return (
      <Box
        display={['flex', 'flex', 'flex', 'none']}
        height={['33px']}
        paddingRight={['0px', theme.gutters[1]]}
        width={['100%', '50%']}
      >
        <span className="filter-by">Filter by</span>
        <div id="custom-select-dropdown">
          <label htmlFor="tag-list" />
          <select
            id="tag-list"
            autoComplete="none"
            onChange={this.handleOnChange}
          >
            {navItems.map(({ label, url, isCurrent }) => (
              <option
                key={`blog-filter-nav-tag-${url}`}
                value={`/blog${url}`}
                selected={isCurrent}
              >
                {label}
              </option>
            ))}
          </select>
          <div className="dropdown-container">
            <DropdownArrowIcon />
          </div>
        </div>
      </Box>
    );
  }
}

const BaseBlogNav = ({
  navItems,
  isHome,
  isTag,
  isAuthor,
  location: locationAndNavigate,
}) => (
  <nav className="site-nav">
    {isHome && (
      <>
        {/* NOTE: responsive is handled in each each child component here */}
        <BlogNavHorizontalMenu navItems={navItems} />
        <BlogNavDropdownMenu
          navItems={navItems}
          navigate={locationAndNavigate.navigate}
        />
      </>
    )}
    {(isTag || isAuthor) && <p className="site-nav-total">totals go here</p>}
  </nav>
);

export const BlogNav = withLocation(BaseBlogNav);
