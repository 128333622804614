/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { liftNode } from 'utils';
import { Grid, Image, Link, Text, Spacing } from 'components';
import { Box, theme as coreTheme } from '../../design-system';

export const BlogsGrid = ({
  paddingH = 'none',
  theme,
  tag = '',
  slug = '',
  blogsUrls,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allGhostPost(sort: { order: DESC, fields: [published_at] }) {
        edges {
          node {
            slug
            title
            feature_image
            tags {
              slug
            }
          }
        }
      }
    }
  `);

  // default: recent posts
  let type = 'recent';
  let posts = data.allGhostPost.edges
    .filter(
      (cur) =>
        cur.node.slug !== slug &&
        !cur.node.tags.map((curTag) => curTag.slug).includes(tag),
    )
    .slice(0, 2);

  if (tag !== '' || slug !== '') {
    type = 'related';
    posts = data.allGhostPost.edges
      .filter(
        (cur) =>
          cur.node.slug !== slug &&
          cur.node.tags.map((curTag) => curTag.slug).includes(tag),
      )
      // concat recent blogs if there's not enough related posts
      .concat(posts)
      .slice(0, 2);
  } else if (blogsUrls) {
    type = 'curated';
    posts = data.allGhostPost.edges.filter((cur) =>
      blogsUrls.includes(cur.node.slug),
    );
  }
  posts = posts.map(liftNode);

  const cells = posts.map(({ title, slug, authors, ...post }) => {
    const linkConfig = {
      props: { url: `/blog/${slug}` },
    };

    const ImageContainer = (
      <Box position="relative" pb="100%">
        <Image
          position="absolute"
          width="100%"
          height="100%"
          imageStyles={{ objectFit: 'cover', height: '100%' }}
          overideUrl={`https://res.cloudinary.com/rangle/image/fetch/q_auto,f_auto/${
            post.feature_image
          }`}
        />
      </Box>
    );

    return posts.length !== 2 && type === 'curated' ? (
      <Box
        key={`article-card-post-slug-${slug}`}
        data-qaid={title ? 'ArticleCard' : null}
        flex="1"
        bg="yellow"
        theme={theme}
      >
        WE NEED 2 BLOGS
      </Box>
    ) : (
      <Box
        key={`article-card-post-slug-${slug}`}
        data-qaid={title ? 'ArticleCard' : null}
        flex="1"
        bg={coreTheme.colors.bg[2]}
      >
        {ImageContainer}
        <Spacing height="small" />
        <Link
          type="medium"
          label={title}
          linkConfig={linkConfig}
          paddingH="small"
        />
        <Spacing height="xxSmall" />
        <Text type="Small Body" paddingH="small">
          Blog
        </Text>
        <Spacing height="small" />
      </Box>
    );
  });

  return (
    <Grid
      paddingH={paddingH}
      cells={cells}
      gutters="large"
      cellBottomPadding="medium"
    />
  );
};
