import React from 'react';

export const DropdownArrowIcon = () => {
  return (
    <svg
      id="dropdown-arrow"
      className="icon-dropdown-arrow"
      width="10px"
      height="10px"
      viewBox="0 0 10 10"
    >
      <g
        id="8---Blog"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Mobile-320px-Blog-tag-dropdown-selected"
          transform="translate(-290.000000, -272.000000)"
          fill="#262626"
        >
          <g id="Filter" transform="translate(20.000000, 264.000000)">
            <g
              id="Icon/Dropdown/Open"
              transform="translate(275.000000, 13.000000) rotate(180.000000) translate(-275.000000, -13.000000) translate(270.000000, 8.000000)"
            >
              <polygon id="▼" points="5 10 0 0 10 0" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
