import React from 'react';
import { Link } from 'design-system';

export const BlogPagination = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext;

  return (
    <nav className="pagination-links-wrapper" role="navigation">
      {previousPagePath && (
        <Link className="pagination-link" href={previousPagePath} rel="prev">
          &larr; Newer Posts
        </Link>
      )}
      {nextPagePath && (
        <Link className="pagination-link" href={nextPagePath} rel="next">
          Older Posts &rarr;
        </Link>
      )}
    </nav>
  );
};
