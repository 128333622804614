import React from 'react';
import { theme, Box, Link, Text, Image } from 'design-system';
import { LargeHeading, MediumHeading, TextParagraph } from 'components';
import { BlogBreadcrumb } from 'partials';

const metaValueProps = {
  fontSize: ['bodyS', 'bodyS', 'bodyS', 'bodyM'],
  fontFamily: 'regular',
  color: theme.colors.brand[1],
  lineHeight: '1.6em',
};

const PostHeaderMetaTitle = (props) => (
  <TextParagraph {...props} fontSize="bodyS" fontFamily="regular" />
);

const metaWrapperProps = {
  flexDirection: ['column', 'row'],
  flexWrap: ['wrap', 'nowrap'],
  mx: ['0px', theme.gutters[1] * -1],
};

const metaContentProps = {
  flex: '1',
  mb: theme.verticalSpace.medium,
  pt: theme.verticalSpace.xSmall,
  borderTop: `1px solid ${theme.colors.uiDivider}`,
  mx: ['0px', theme.gutters[1]],
};
/* eslint-disable no-unused-vars */
const TagsMetaWithLinks = ({ tags }) =>
  tags.map(({ title: tagTitle, slug }, tagIndex) => {
    return (
      <>
        <Link href={`/podcasts/tag/${slug}`} {...metaValueProps}>
          {tagTitle}
        </Link>
        {tagIndex + 1 !== tags.length && ', '}
      </>
    );
  });
/* eslint-enable no-unused-vars */

const TagsMetaWithoutLinks = ({ tags }) => (
  <Text {...metaValueProps}>
    {tags.map(
      ({ title: tagTitle }, tagIndex) =>
        `${tagTitle}${tagIndex + 1 !== tags.length ? ', ' : ''}`,
    )}
  </Text>
);

const PageSubtitle = ({ seasonNumber, episodeNumber }) => (
  <MediumHeading
    text={`Season ${seasonNumber} / Episode ${episodeNumber}`}
    color={theme.colors.brand[0]}
    fontFamily="light"
  />
);

const PageImage = ({ imgSrc }) => <Image width="100%" src={imgSrc} />;

const PageTitle = ({ title }) => (
  <LargeHeading variant="smaller" text={title} />
);

const MobileHeaderLayout = ({ title, subtitle, image }) => (
  <>
    <Box width={1} display="flex" mb={theme.verticalSpace.medium}>
      <Box width="144px">{image}</Box>
      <Box width="auto" pl={theme.doubleGutters[0]}>
        {subtitle}
      </Box>
    </Box>
    <Box width={1}>{title}</Box>
  </>
);

const NotMobileHeaderLayout = ({ title, subtitle, image }) => (
  <>
    <Box width={['204px', '204px', '204px', '204px']}>{image}</Box>
    <Box
      display="flex"
      flexWrap="wrap"
      alignContent="flex-start"
      pl={theme.doubleGutters[1]}
    >
      <Box width="100%" mb={theme.verticalSpace.medium}>
        {subtitle}
      </Box>
      <Box width="100%">{title}</Box>
    </Box>
  </>
);

const PodcastPageHeader = (props) => (
  <>
    <Box display={['flex', 'none']} flexWrap="wrap">
      <MobileHeaderLayout {...props} />
    </Box>
    <Box display={['none', 'flex']} flexWrap="nowrap">
      <NotMobileHeaderLayout {...props} />
    </Box>
  </>
);

export const PodcastHeader = ({
  title,
  people,
  tags,
  publishedDate,
  podcastSeries,
  seasonNumber,
  episodeNumber,
}) => (
  <Box
    is="header"
    mb={[
      theme.verticalSpace.medium - metaContentProps.mb,
      theme.verticalSpace.medium,
    ]}
  >
    <BlogBreadcrumb url="/podcasts/" text="Podcasts" />
    <Box
      flex=" 0 0 auto"
      display=" flex"
      flexWrap=" wrap"
      alignContent="flex-start"
      mb={[
        theme.verticalSpace.medium,
        theme.verticalSpace.medium,
        theme.verticalSpace.medium,
        theme.verticalSpace.large,
      ]}
      maxWidth=" 100%"
      width=" 100%"
    >
      <PodcastPageHeader
        image={
          <PageImage
            imgSrc={podcastSeries.props.featuredImage.props.file.url}
          />
        }
        title={<PageTitle title={title} />}
        subtitle={
          <PageSubtitle
            seasonNumber={seasonNumber}
            episodeNumber={episodeNumber}
          />
        }
      />
    </Box>
    <Box display="flex" justifyContent="space-between" {...metaWrapperProps}>
      <Box {...metaContentProps}>
        <PostHeaderMetaTitle>Featuring</PostHeaderMetaTitle>
        <Box>
          <Text {...metaValueProps}>
            {people.map(
              (name, peopleIndex) =>
                `${name}${peopleIndex + 1 !== people.length ? ', ' : ''}`,
            )}
          </Text>
        </Box>
      </Box>
      <Box {...metaContentProps}>
        <PostHeaderMetaTitle>Date published</PostHeaderMetaTitle>
        <TextParagraph {...metaValueProps}>{publishedDate}</TextParagraph>
      </Box>
      <Box {...metaContentProps}>
        <PostHeaderMetaTitle>Tags</PostHeaderMetaTitle>
        <Box>
          <TagsMetaWithoutLinks tags={tags} />
        </Box>
      </Box>
    </Box>
  </Box>
);
