import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { BlogBreadcrumb, BlogNav } from 'partials';
import { isBlogRouteActive, withLocation } from 'utils';
import { theme, Box, verticalSpace } from 'design-system';
import { Spacing } from 'components';

import { Text } from '../../components/modules/Typography.Style';

const BaseBlogHeader = ({
  isHome,
  isPaged,
  isTag,
  isAuthor,
  tag,
  author,
  location: locationAndNavigate,
  title = 'Blog',
}) => {
  const data = useStaticQuery(graphql`
    query GhostTagsQuery {
      allGhostSettings {
        edges {
          node {
            navigation {
              label
              url
            }
          }
        }
      }
    }
  `);

  const ghostNavItems = data.allGhostSettings.edges[0].node.navigation.map(
    (navItem) => ({
      ...navItem,
      isCurrent: isBlogRouteActive(
        locationAndNavigate.location.pathname,
        navItem.url,
      ),
    }),
  );

  return (
    <Box is="header" px={[theme.gutters[1]]} mb={verticalSpace.large}>
      <Box
        display="flex"
        flex="0 0 auto"
        flexDirection="column"
        width="100%"
        lineHeight="1em"
      >
        {(isHome || isPaged) && (
          <Text className="site-title" type="Large Heading" content={title} />
        )}
        <Spacing height="Small" />
        {isTag && tag && (
          <>
            <BlogBreadcrumb />
            <Text type="Large Heading" content={tag.name} />
          </>
        )}
        {isAuthor && author && (
          <>
            <BlogBreadcrumb />
            <Text
              className="author-title"
              type="Large Heading"
              content={author.name}
            />
          </>
        )}
      </Box>
      {(isHome || isTag) && <BlogNav isHome navItems={ghostNavItems} />}
    </Box>
  );
};

export const BlogHeader = withLocation(BaseBlogHeader);
