import React from 'react';

export const SearchCloseIcon = (props) => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38 16.1178L35.8822 14L26 23.8822L16.1179 14L14.0001 16.1178L23.8822 26L14 35.8822L16.1178 38L26 28.1178L35.8823 38L38.0001 35.8822L28.1178 26L38 16.1178Z"
      fill="currentColor"
    />
  </svg>
);
