export const filterApplicators = {
  podcast: {
    byPrimaryTag: (tagSlugs) => ({ props }) =>
      tagSlugs.includes(props.primaryTag.props.slug),
    byAllTags: (tagSlugs) => ({ props }) =>
      (props.tags || []).some(({ props: tagProps }) =>
        tagSlugs.includes(tagProps.slug),
      ),
  },
  blog: {
    byPrimaryTag: (tagSlugs) => ({ primary_tag: primaryTag }) =>
      tagSlugs.includes(primaryTag.slug),
    byAllTags: (tagSlugs) => ({ tags = [] }) =>
      tags.some(({ slug }) => tagSlugs.includes(slug)),
  },
};
