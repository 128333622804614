import React from 'react';

import {
  theme,
  Box,
  Column,
  Row,
  Text as BaseText,
  withStyles,
} from 'design-system';
import { IconLink } from 'components';

const Text = withStyles(
  (themeArg, props, themeType = 'light') => ({
    default: {
      ...themeArg.typography[themeType].body1,
    },
    heading: {
      ...themeArg.typography[themeType].body1,
      color: 'text.0',
    },
  }),
  BaseText,
);

const SmallCTA = withStyles(
  (themeArg, props, themeType = 'light') => ({
    default: {
      ...themeArg.typography[themeType].body2,
      colors: `${themeType}BackgroundCTAs`,
    },
  }),
  IconLink,
);

const CareersList = ({ postings }) => (
  <Box>
    <Row borderBottom={1} pb="8px">
      <PositionColumn>
        <Text variant="heading">Position</Text>
      </PositionColumn>
      <SecondaryColumn>
        <Text variant="heading">Specialty</Text>
      </SecondaryColumn>
      <SecondaryColumn>
        <Text variant="heading">Location</Text>
      </SecondaryColumn>
    </Row>
    {postings.map((posting) => (
      <Row key={posting.id} py="32px" borderBottom={1} alignItems="center">
        <PositionColumn>
          <SmallCTA
            internal
            iconAfter="→"
            url={`/careers/${posting.humanId}`}
            text={posting.text}
            ellipsis
          />
        </PositionColumn>
        <SecondaryColumn>
          <Text ellipsis>{posting.categories.team}</Text>
        </SecondaryColumn>
        <SecondaryColumn>
          <Text ellipsis>{posting.categories.location}</Text>
        </SecondaryColumn>
      </Row>
    ))}
  </Box>
);

const PositionColumn = ({ children }) => (
  <Column width={[1, 1 / 3, 1 / 2]} px={0} pr={theme.gutters}>
    {children}
  </Column>
);

const SecondaryColumn = ({ children }) => (
  <Column width={[1, 1 / 3, 1 / 4]} display={['none', 'initial']}>
    {children}
  </Column>
);

export default CareersList;
