/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Helmet from 'react-helmet';

export const HelmetScripts = () => (
  <Helmet>
    <script
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      type="text/javascript"
      charset="UTF-8"
      data-domain-script="5dc95b81-11bb-47b1-8a97-f199ed200a3a"
      defer
      async
    />

    <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
    <script async>{`
      (function(w,d,s,l,i){
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j,f);
      })(window, document, 'script', 'dataLayer', 'GTM-MB28X6B');
  `}</script> 
  <script src="https://www.googleoptimize.com/optimize.js?id=GTM-K8ZHV9G"></script>
  </Helmet>
);
