import { graphql, useStaticQuery } from 'gatsby';
import moment from 'moment';
import { getImageURL } from 'utils';

const allDataQuery = graphql`
  query AllDataQuery {
    mostRecentPodcasts: allContentfulPodcastTree(limit: 3) {
      edges {
        node {
          componentTree
        }
      }
    }
  }
`;

const createRenderObjForPodcast = ({ nodeType, props: podcastProps }) => ({
  nodeType,
  title: podcastProps.heading,
  imageUrl: getImageURL(podcastProps.featuredImage),
  publishedDate: moment(podcastProps.publishedDate).format('MMMM D, YYYY'),
  tagsString: (podcastProps.tags || [])
    .map(({ props: tagProps }) => tagProps.title)
    .join(', '),
  linkUrl: `/podcasts/${podcastProps.slug}`,
});

const renderNormalizers = {
  podcast: createRenderObjForPodcast,
};

export const getRelatedContent = () => {
  const { mostRecentPodcasts } = useStaticQuery(allDataQuery);

  return mostRecentPodcasts.edges
    .map(({ node }) => JSON.parse(node.componentTree))
    .map((node) => ({
      ...node,
      nodeType: 'podcast',
      publishedAt: node.props.publishedDate,
      slug: node.props.slug,
    }))
    .map((node) => renderNormalizers[node.nodeType](node));
};
