import React from 'react';
import _flow from 'lodash/flow';

import { liftNode } from 'utils';
import * as variants from './variants';

const generatePodcastsList = (queryData) =>
  queryData.allContentfulPodcastTree.edges
    .map(liftNode)
    .map(({ componentTree }) => JSON.parse(componentTree))
    .map(({ props }) => props);
const restrictPodcastsInSeason = ({ podcastSeason: currentSeason }) => (
  podcasts,
) =>
  podcasts.filter(
    ({ podcastSeason: season }) => season.entryId === currentSeason.entryId,
  );
const sortPodcastsInSeason = (podcasts) =>
  podcasts.sort(({ episodeId: episodeIdA }, { episodeId: episodeIdB }) => {
    const episodeNumberA = parseInt(episodeIdA, 10);
    const episodeNumberB = parseInt(episodeIdB, 10);
    return episodeNumberA > episodeNumberB ? 1 : -1;
  });
const generatePodcastLinkSpecs = (currentPodcast) => (podcasts) =>
  podcasts.map(({ slug, heading, episodeId, featuredImage }) => ({
    href: `/podcasts/${slug}`,
    isCurrent: slug === currentPodcast.slug,
    heading,
    imageSrc: featuredImage.props.file.url,
    episodeId,
  }));

const podcastSpecEpic = ({ queryData, currentPodcastProps }) =>
  _flow(
    generatePodcastsList,
    restrictPodcastsInSeason(currentPodcastProps),
    sortPodcastsInSeason,
    generatePodcastLinkSpecs(currentPodcastProps),
  )(queryData);

export const PodcastsInSeason = ({
  currentPodcast,
  showHeading = true,
  withCopyMediaGroup = false,
  variant = 'SidebarList',
  podcastsInSeasonQuery,
}) => {
  const currentPodcastProps = currentPodcast.props;
  const queryData = podcastsInSeasonQuery;
  const podcastLinkSpecs = podcastSpecEpic({ queryData, currentPodcastProps });
  const {
    podcastSeason: { props: podcastSeasonProps },
  } = currentPodcastProps;
  const {
    heading: seasonHeading,
    description: seasonDescription,
    number: seasonNumber,
  } = podcastSeasonProps;

  const VariantComponent = variants[variant];
  const variantProps = {
    showHeading,
    withCopyMediaGroup,
    podcastLinkSpecs,
    seasonHeading,
    seasonDescription,
    seasonNumber,
  };

  return <VariantComponent {...variantProps} />;
};
