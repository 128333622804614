/* eslint-disable no-return-assign */
import React from 'react';
import styled from 'styled-components';
import {
  border,
  space,
  color,
  layout,
  flexbox,
  typography,
  colorStyle,
} from 'styled-system-next';
import { Box } from 'design-system';
import { camelize } from '../patterns';
import { horizontalSpace, theme } from '../../design-system/theme';

const styles = {
  display: 'flex',
  height: ['57px', '62px', '62px', '74px'],
  colors: 'lightEmailCapture',
};

const darkStyles = {
  colors: 'darkEmailCapture',
};

const darkInputStyles = {
  backgroundColor: theme.colors.black,
  color: theme.colors.white,
};

const darkButtonStyles = {
  colors: 'darkEmailCaptureButton',
};

const BaseStyle = styled.div.attrs({
  fontFamily: 'rangle riforma',
  fontSize: ['bodyM', 'bodyM', 'bodyM', 'bodyL'],
})`
  ${border}
  ${space}
  ${color}
  ${layout}
  ${flexbox}
  ${typography}
  ${colorStyle}
`;

const StyledInput = styled(BaseStyle)`
  outline: none;
  &::placeholder {
    color: ${theme.colors.darkGrey};
  }
  &.dark::placeholder {
    color: ${theme.colors.grey};
  }
  min-width: 0;
  width: 0;
  flex: 1;
`;
StyledInput.defaultProps = {
  flex: 1,
  px: 'small',
  py: '20px',
  border: 'none',
};

const StyledButton = styled(BaseStyle)``;
StyledButton.defaultProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  colors: 'lightEmailCaptureButton',
  height: ['55px', '60px', '60px', '72px'],
  width: ['55px', '60px', '60px', '72px'],
};

export class EmailCapture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({
      email: e.target.value,
    });
  }

  render() {
    const { theme = 'light', paddingH = 'none', linkConfig } = this.props;
    const { email } = this.state;

    if (linkConfig && linkConfig.props.url) {
      return (
        <Box width="100%" px={horizontalSpace[camelize(paddingH)]}>
          <Box {...styles} {...(theme === 'dark' ? darkStyles : null)}>
            <StyledInput
              as="input"
              className={theme === 'dark' ? 'dark' : null}
              type="text"
              placeholder="Email address"
              onFocus={(e) => (e.target.placeholder = '')}
              onBlur={(e) => (e.target.placeholder = 'Email address')}
              {...(theme === 'dark' ? darkInputStyles : null)}
              value={email}
              onChange={this.handleChange}
            />
            <StyledButton
              {...(theme === 'dark' ? darkButtonStyles : null)}
              as="button"
              href={`${linkConfig.props.url}?email=${email}`}
            >
              →
            </StyledButton>
          </Box>
        </Box>
      );
    }

    return <span>No LinkConfig was provided</span>;
  }
}
