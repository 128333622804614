/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Box, verticalSpace } from 'design-system';
import { Grid, Image, Link, Spacing, Text } from 'components';

const podcastGridCards = (podcasts) =>
  podcasts.map(({ href, heading, imageSrc, episodeId }) => {
    const linkConfig = { props: { url: href } };
    return (
      <Box key={`article-card-post-slug-${href}`}>
        <Image overideUrl={imageSrc} />
        <Spacing height="xxSmall" />
        <Link linkConfig={linkConfig}>{heading}</Link>
        <Spacing height="xxxSmall" />
        <Text type="Small Body">{`Episode ${episodeId}`}</Text>
      </Box>
    );
  });

const Header = ({ heading, number, copy }) => (
  <Box>
    <Text type="Medium Heading">{`Season ${number}`}</Text>
    <Spacing height="xSmall" />
    <Text type="Large Heading">{heading}</Text>
    <Spacing height="xSmall" />
    <Text type="Medium Body" data-qaid={copy ? 'text' : null}>
      {copy}
    </Text>
  </Box>
);

export const LandingCards = ({
  podcastLinkSpecs,
  seasonHeading,
  seasonDescription,
  seasonNumber,
}) => (
  <Box pb={verticalSpace.large}>
    <Box mb={verticalSpace.medium} width={[1, 5 / 7]}>
      <Header
        heading={seasonHeading}
        copy={seasonDescription}
        number={seasonNumber}
      />
    </Box>
    <Grid
      columnRatio="1:1:1"
      gutters="small"
      cellBottomPadding="medium"
      cells={podcastGridCards(podcastLinkSpecs)}
    />
  </Box>
);
