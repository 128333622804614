import React from 'react';
import { Box, horizontalSpace, verticalSpace } from 'design-system';

import { NavigationItem } from '../NavigationItem';
import { Text } from '../../modules';

export class NavigationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedId: this.findExpandedMenu(props),
    };
  }

  componentWillReceiveProps(nextProp) {
    if (this.props.pathname !== nextProp.pathname) {
      this.setState({
        expandedId: this.findExpandedMenu(nextProp),
      });
    }
  }

  findExpandedMenu = (props) => {
    const { pathname, children } = props;
    let matchedMenuItem;
    children.find(({ props: itemProps = {} }) => {
      if (itemProps.subItems) {
        console.log(itemProps.subItems);
        itemProps.subItems.find(({ props: subItemProps = {} }) => {
          if (pathname.startsWith(subItemProps.link)) {
            matchedMenuItem = itemProps.entryId;
          }
        });
      }
    });
    return matchedMenuItem;
  };

  updateExpandedId = (id) => {
    const { expandedId } = this.state;

    this.setState({
      expandedId: expandedId !== id ? id : undefined,
    });
  };

  render = () => {
    const { children, theme, pathname, ...props } = this.props;
    const { expandedId } = this.state;

    return (
      <Box
        is="ul"
        p={0}
        css="list-style: none;"
        {...props}
        id="site-navigation"
      >
        {children.map(({ props = {} }) => {
          const isOpen = expandedId === props.entryId;

          return (
            <Box pb={isOpen ? 0 : verticalSpace.small}>
              <NavigationItem
                key={props.entryId}
                isOpen={isOpen}
                isActive={isOpen}
                theme={theme}
                updateExpandedId={() => this.updateExpandedId(props.entryId)}
                {...props}
              />
              <Box
                display={isOpen ? 'block' : 'none'}
                mt={verticalSpace.small}
                ml={horizontalSpace.small}
              >
                {props.subItems &&
                  props.subItems.map(({ props = {} }, index) => {
                    const isActive = pathname.startsWith(props.link);
                    return props.componentType === 'text' ? (
                      <Text
                        letterSpacing="xLarge"
                        theme="dark"
                        pt={index !== 0 ? verticalSpace.medium : '0px'}
                        pb={verticalSpace.small}
                        {...props}
                      />
                    ) : (
                      <NavigationItem
                        isSubItem
                        key={props.entryId}
                        theme={theme}
                        isActive={isActive}
                        {...props}
                      />
                    );
                  })}
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  };
}
