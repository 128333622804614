import React from 'react';
import _flow from 'lodash/flow';
import { liftNode } from 'utils';
import { Text, Spacing, PodcastsInSeason } from 'components';
import { Box, verticalSpace, horizontalSpace } from 'design-system';

const generatePodcastsList = (queryData) =>
  queryData.allContentfulPodcastTree.edges
    .map(liftNode)
    .map(({ componentTree }) => JSON.parse(componentTree))
    .map(({ props }) => props);

const generatePodcastSeasonsList = (podcasts) =>
  podcasts
    .map(({ podcastSeason }) => podcastSeason)
    .reduce(
      (podcastSeasons, currentPodcastSeason) =>
        podcastSeasons.find(
          ({ entryId }) => entryId === currentPodcastSeason.entryId,
        )
          ? podcastSeasons
          : [...podcastSeasons, currentPodcastSeason],
      [],
    );

const restrictSeasonsBySeries = ({ entryId: currentSeriesEntryId }) => (
  podcastSeasons,
) =>
  podcastSeasons.filter(
    ({ props: { podcastSeries } }) =>
      podcastSeries.entryId === currentSeriesEntryId,
  );

const generatePodcastsInSeasonSpecs = (podcastSeasons) =>
  podcastSeasons.map((podcastSeason) => ({ props: { podcastSeason } }));

const compare = (test) => (
  { props: { number: a } },
  { props: { number: b } },
) =>
  // eslint-disable-next-line no-nested-ternary
  test(a, b) ? 1 : test(b, a) ? -1 : 0;

const greater = (a, b) => a > b;

const lesser = (a, b) => a < b;
const SEASONS_SORT_TYPES_MAP = {
  'Latest First': compare(lesser),
  'Oldest First': compare(greater),
};

const sortSeasons = (seasonsSortType) => (podcastSeasons) =>
  podcastSeasons.sort(SEASONS_SORT_TYPES_MAP[seasonsSortType]);

const podcastsInSeasonSpecEpic = ({
  queryData,
  currentSeriesReference,
  seasonsSortType,
}) =>
  _flow(
    generatePodcastsList,
    generatePodcastSeasonsList,
    // restrictSeasonsBySeries(currentSeriesReference), // Allow any series to appear
    sortSeasons(seasonsSortType),
    generatePodcastsInSeasonSpecs,
  )(queryData);

export const PodcastsInSeries = ({
  podcastsInSeries,
  podcastsInSeriesQuery,
  pageHeader,
}) => {
  const {
    props: { podcastSeries: currentSeriesReference, seasonsSortType },
  } = podcastsInSeries;

  const queryData = podcastsInSeriesQuery;
  const podcastsInSeasonSpecs = podcastsInSeasonSpecEpic({
    queryData,
    currentSeriesReference,
    seasonsSortType,
  });
  return (
    <Box px={horizontalSpace.layout}>
      <Box pb={verticalSpace.xLarge}>
        <Spacing height="xLarge" />
        <Text type="Large Heading">{pageHeader}</Text>
        <Spacing height="medium" />

        {podcastsInSeasonSpecs.length
          ? podcastsInSeasonSpecs.map((podcastSeason, podcastSeasonIndex) => (
              <Box
                key={`podcastsInSeries-podcastsInSeason-${
                  podcastSeason.props.podcastSeason.entryId
                }`}
                mb={
                  podcastSeasonIndex !== podcastsInSeasonSpecs.length - 1
                    ? [verticalSpace.mediumLarge, verticalSpace.large].map(
                        (space) => space - verticalSpace.medium,
                      )
                    : []
                }
              >
                <PodcastsInSeason
                  currentPodcast={podcastSeason}
                  showHeading={false}
                  withCopyMediaGroup
                  variant="LandingCards"
                  podcastsInSeasonQuery={podcastsInSeriesQuery}
                />
              </Box>
            ))
          : null}
      </Box>
    </Box>
  );
};
