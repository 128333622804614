import React from 'react';
import { SmallCTA, IconLink } from 'components';
import { theme, Box, Caption, Text } from 'design-system';

const TitleUnlinked = ({ text }) => (
  <Box display={['flex']} flexWrap={['nowrap']} alignItems={['center']}>
    <Text
      fontFamily="light"
      fontSize={['bodyM', 'bodyM', 'bodyM', 'bodyL'] /* old [2, 2, 2, 3] */}
      lineHeight={[4, 4, 4, 6]}
    >
      {text}
    </Text>
  </Box>
);

const TitleLinked = ({ href, text }) => (
  <SmallCTA iconLink={<IconLink url={href} text={text} internal />} />
);

const baseCaptionProps = {
  is: 'p',
  mt: theme.verticalSpace.xSmall,
  mb: 0,
};

const CaptionLinked = ({ episodeId }) => (
  <Caption {...baseCaptionProps}>Episode {episodeId}</Caption>
);

const CaptionUnlinked = ({ episodeId }) => (
  <Caption {...baseCaptionProps} color={theme.colors.brand[0]}>
    Episode {episodeId} • Now Playing
  </Caption>
);

export const PodcastLink = ({ episodeId, heading, href, isCurrent }) => (
  <Box mb={[theme.verticalSpace.small, theme.verticalSpace.medium]}>
    {isCurrent ? (
      <>
        <TitleUnlinked text={heading} />
        <CaptionUnlinked episodeId={episodeId} />
      </>
    ) : (
      <>
        <TitleLinked text={heading} href={href} />
        <CaptionLinked episodeId={episodeId} />
      </>
    )}
  </Box>
);
