import React from 'react';
import queryString from 'query-string';

import { Wrapper, Box, horizontalSpace } from 'design-system';
import { Select, Link, Text, Spacing } from 'components';

import List from './List';

const SPECIALTY_EMPTY = { value: 'none', label: 'Specialty' };
const LOCATION_EMPTY = { value: 'none', label: 'Location' };
const SELECT_ALL = { value: 'all', label: 'All' };

class CareersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      specialtyFilter: SPECIALTY_EMPTY,
      locationFilter: LOCATION_EMPTY,
    };
  }

  componentDidMount() {
    const {
      buildContext: {
        leverBuildContext: { specialties, locations },
      },
    } = this.props;

    const {
      location: locationParam,
      specialty: specialtyParam,
    } = queryString.parse(location.search);

    if (locationParam || specialtyParam) {
      this.setState({
        ...this.state,
        specialtyFilter: specialties[specialtyParam]
          ? { value: specialtyParam, label: specialties[specialtyParam] }
          : SPECIALTY_EMPTY,
        locationFilter: locations[locationParam]
          ? { value: locationParam, label: locations[locationParam] }
          : LOCATION_EMPTY,
      });
    }
  }

  handleFilter = (filter, value) => this.setState({ [filter]: value });

  render() {
    const {
      buildContext: {
        leverBuildContext: { specialties = {}, locations = {}, postings = {} },
      },
    } = this.props;
    const { specialtyFilter, locationFilter } = this.state;

    // Build craft options.
    const specialtyOptions = Object.entries(
      specialties,
    ).map(([value, label]) => ({ value, label }));
    const locationOptions = Object.entries(locations).map(([value, label]) => ({
      value,
      label,
    }));

    // Filter postings.
    const filteredPostings = Object.entries(postings).reduce(
      (filtered, [, posting]) => {
        if (
          // Filter is empty ...
          (specialtyFilter.value === SPECIALTY_EMPTY.value ||
            // All is selected ...
            specialtyFilter.value === SELECT_ALL.value ||
            // Specialty is selected ...
            specialtyFilter.value === posting.specialtyId) &&
          // Filter is empty ...
          (locationFilter.value === LOCATION_EMPTY.value ||
            // All is selected ...
            locationFilter.value === SELECT_ALL.value ||
            // Location is selected ...
            locationFilter.value === posting.locationId)
        ) {
          // ... include this posting.
          return [...filtered, posting];
        }
        return filtered;
      },
      [],
    );

    const isSpecialtyFilterSelected =
      specialtyFilter.value !== SPECIALTY_EMPTY.value &&
      specialtyFilter.value !== SELECT_ALL.value;

    const isLocationFilterSelected =
      locationFilter.value !== LOCATION_EMPTY.value &&
      locationFilter.value !== SELECT_ALL.value;

    return (
      <Wrapper {...this.props} px={horizontalSpace.layout}>
        {/* TODO: Add back later or just deprecate */}
        {/* <Box display="flex" flexDirection={['column', 'row']} mb="large">
          <Box
            is="label"
            id="career-filter"
            mr="medium"
            mb={['medium', 'none']}
          >
            <Text type="Medium Body">Filter by</Text>
          </Box>
          <Box flex={1} mr={['none', 'medium']} mb={['medium', 'none']}>
            <Select
              containerProps={{ css: 'flex-grow: 1; min-width:0;' }}
              labelledBy="career-filter"
              placeholder="Specialty"
              options={
                isSpecialtyFilterSelected
                  ? [SELECT_ALL, ...specialtyOptions]
                  : specialtyOptions
              }
              onChange={(value) => this.handleFilter('specialtyFilter', value)}
              value={isSpecialtyFilterSelected ? specialtyFilter : null}
            />
          </Box>
          <Box flex={1}>
            <Select
              containerProps={{ css: 'flex-grow: 1; min-width:0;' }}
              labelledBy="career-filter"
              placeholder="Location"
              options={
                isLocationFilterSelected
                  ? [SELECT_ALL, ...locationOptions]
                  : locationOptions
              }
              onChange={(value) => this.handleFilter('locationFilter', value)}
              value={isLocationFilterSelected ? locationFilter : null}
            />
          </Box>
        </Box> */}

        {filteredPostings.length > 0 && (
          <>
            <List postings={filteredPostings} />
            <Spacing height="xLarge" />
          </>
        )}
        <Text type="Medium Heading">
          {filteredPostings.length > 0
            ? 'Don’t see an exact match?'
            : 'There’s nothing listed here now'}
        </Text>
      </Wrapper>
    );
  }
}

export {
  CareersList,
  CareersList as JobPostingsList, // Downward compatibility.
};
