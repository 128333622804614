import moment from 'moment';

export const sortNodes = (nodes) =>
  nodes.sort((nodeA, nodeB) => {
    const momentA = moment(nodeA.publishedAt);
    const momentB = moment(nodeB.publishedAt);
    if (momentA.isSame(momentB)) return 0;
    if (momentA.isAfter(momentB)) return -1;
    if (momentB.isAfter(momentA)) return 1;
    return 0;
  });
