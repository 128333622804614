const sanitizeInput = (input) => parseInt(input, 10);

export const paginateNodes = ({
  perPage: perPageParam,
  currentPage: currentPageParam,
}) => (nodes) => {
  const perPage = sanitizeInput(perPageParam);
  const currentPage = sanitizeInput(currentPageParam);
  const start = (currentPage - 1) * perPage;
  const end = start + perPage;
  return nodes.slice(start, end);
};
