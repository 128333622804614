/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import get from 'lodash/get';
import includes from 'lodash/includes';

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
} from 'react-html-parser';

import { Text } from 'components';
import { StyledListItems, LinkStyling } from '../../../../patterns/Markdown';

const transform = (node, index) => {
  if (node.type === 'tag') {
    // Blocks.
    if (node.name === 'div') {
      return (
        <Text type="Medium Body">
          {processNodes(node.children || [], transform)}
        </Text>
      );
    }

    // Headings.
    if (
      node.name === 'b' &&
      (includes(node.attribs.style, 'font-size: 18px') ||
        includes(node.attribs.style, 'font-size: 24px'))
    ) {
      return (
        <Text type="Medium Heading">
          {processNodes(node.children || [], transform)}
        </Text>
      );
    }

    // List items.
    if (node.name === 'li') {
      return (
        <StyledListItems>
          <Text
            type="Medium Body"
            style={{ textIndent: '0', marginTop: '-20px' }}
          >
            {processNodes(node.children || [], transform)}
          </Text>
        </StyledListItems>
      );
    }

    // Anchors.
    if (node.name === 'a') {
      return (
        <LinkStyling href={get(node, 'attribs.href')}>
          {processNodes(node.children || [], transform)}
        </LinkStyling>
      );
    }
  }

  const transformedNode = {
    ...node,
    attribs: {
      // Strip attributes.
      href: get(node, 'attribs.href'),
      target: get(node, 'attribs.target'),
    },
  };

  return convertNodeToElement(transformedNode, index, transform);
};

const htmlParser = (html, options) =>
  ReactHtmlParser(html, { transform, ...options });

export default htmlParser;
