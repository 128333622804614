import React from 'react';
import { SmallHeading } from 'components';
import { Box, Column, theme } from 'design-system';
import { getSiteSettings } from 'utils';

const DefaultHeading = ({ text }) => (
  <SmallHeading
    text={text}
    mb={theme.verticalSpace.xSmall}
    fontFamily="regular"
    color={theme.colors.ui[1]}
  />
);

export const ListenOnCard = ({ listenOnLinks, ...props }) => {
  const siteSettings = getSiteSettings();
  const listenOnHeading =
    siteSettings.props.podcastSettings.props.podcastEpisodeListenOnHeading ||
    'Listen on:';
  return (
    <>
      <Box mb={[theme.verticalSpace.xSmall]}>
        <DefaultHeading text={listenOnHeading} />
      </Box>
      <Column flexBasis="100%" px={0} {...props}>
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          mx={[theme.gutters[0] * -1, `${(theme.gutters[1] / 2) * -1}px`]}
        >
          {React.Children.map(listenOnLinks, (listenOnLink, index) =>
            React.cloneElement(listenOnLink, {
              index,
              wrapperProps: {
                mx: [theme.gutters[0], `${theme.gutters[1] / 2}px`],
                mb: [theme.verticalSpace.small],
                flex: '0 1 auto',
              },
            }),
          )}
        </Box>
      </Column>
    </>
  );
};
