import React from 'react';
import { Box, Link } from 'design-system';
import { useHover } from 'utils';
import googlePlayColor from 'assets/png/googlePlay-color.png';
import googlePlayGrayscale from 'assets/png/googlePlay-grayscale.png';
import googlePlayMobile from 'assets/png/googlePlay-mobile.png';
import iTunesColor from 'assets/png/iTunes-color.png';
import iTunesGrayscale from 'assets/png/iTunes-grayscale.png';
import iTunesMobile from 'assets/png/iTunes-mobile.png';
import spotifyColor from 'assets/png/spotify-color.png';
import spotifyGrayscale from 'assets/png/spotify-grayscale.png';
import spotifyMobile from 'assets/png/spotify-mobile.png';
import stitcherColor from 'assets/png/stitcher-color.png';
import stitcherGrayscale from 'assets/png/stitcher-grayscale.png';
import stitcherMobile from 'assets/png/stitcher-mobile.png';
import youtubeColor from 'assets/png/youtube-color.png';
import youtubeGrayscale from 'assets/png/youtube-grayscale.png';
import youtubeMobile from 'assets/png/youtube-mobile.png';

const imagesDict = {
  color: {
    'Google Play': googlePlayColor,
    iTunes: iTunesColor,
    Spotify: spotifyColor,
    Stitcher: stitcherColor,
    YouTube: youtubeColor,
  },
  grayscale: {
    'Google Play': googlePlayGrayscale,
    iTunes: iTunesGrayscale,
    Spotify: spotifyGrayscale,
    Stitcher: stitcherGrayscale,
    YouTube: youtubeGrayscale,
  },
  mobile: {
    'Google Play': googlePlayMobile,
    iTunes: iTunesMobile,
    Spotify: spotifyMobile,
    Stitcher: stitcherMobile,
    YouTube: youtubeMobile,
  },
};

const MobileLayout = ({ platform, ...props }) => (
  <img alt={platform} src={imagesDict.mobile[platform]} {...props} />
);

const NotMobileLayout = ({ hoverState, platform, ...props }) =>
  hoverState ? (
    <img alt={platform} src={imagesDict.color[platform]} {...props} />
  ) : (
    <img alt={platform} src={imagesDict.grayscale[platform]} {...props} />
  );

const LinkInner = (props) => (
  <>
    <Box display={['flex', 'none']} alignItems="middle">
      <MobileLayout
        {...props}
        // eslint-disable-next-line react/destructuring-assignment
        height={props.platform === 'Stitcher' ? '30px' : '40px'}
      />
    </Box>
    <Box display={['none', 'block']}>
      <NotMobileLayout {...props} height="56px" />
    </Box>
  </>
);

export const ListenOnLink = ({
  platform,
  href,
  linkTitle,
  index,
  wrapperProps,
  ...props
}) => {
  const [hoverState, hoverProps] = useHover();
  return (
    <Box {...wrapperProps}>
      <Link
        {...hoverProps}
        href={href}
        title={linkTitle}
        {...props}
        height="100%"
      >
        <LinkInner
          alt={`Listen on ${platform}`}
          hoverState={hoverState}
          platform={platform}
        />
      </Link>
    </Box>
  );
};
