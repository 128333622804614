import React from 'react';
import { Box, theme } from 'design-system';
import { MediumHeading, RelatedContentCardGrid } from 'components';
import { getSiteSettings } from 'utils';

export const PodcastRelatedContent = ({
  relatedContent,
  cardTitlePaddingBottom,
  forceColumnWidthToThirds,
}) => {
  const siteSettings = getSiteSettings();
  const relatedContentHeading =
    siteSettings.props.podcastSettings.props
      .podcastEpisodeRelatedContentHeading || 'Related Content';
  return (
    <Box
      is="aside"
      mb={theme.verticalSpace.xLarge - theme.verticalSpace.medium}
    >
      <Box>
        <MediumHeading
          text={relatedContentHeading}
          pb={[theme.verticalSpace.small, theme.verticalSpace.medium]}
        />
      </Box>

      <RelatedContentCardGrid
        contentSpecs={relatedContent}
        cardTitlePaddingBottom={cardTitlePaddingBottom}
        forceColumnWidthToThirds={forceColumnWidthToThirds}
      />
    </Box>
  );
};
