import React from 'react';
import _flow from 'lodash/flow';

const sanitizeEmbedCode = (code = '') => {
  const cleanCode = code.split('<script>');
  return cleanCode[0] || '';
};

const transformEmbedCode = (hideEmbedImage) => (code) =>
  hideEmbedImage
    ? code.replace(
        'data-bt-show-art-cover="true"',
        'data-bt-show-art-cover="false"',
      )
    : code;

const embedEpic = ({ hideEmbedImage }) =>
  _flow(sanitizeEmbedCode, transformEmbedCode(hideEmbedImage));

export const PodcastEmbed = ({ embedCode, hideEmbedImage = false, style }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: embedEpic({ hideEmbedImage })(embedCode),
    }}
    style={style}
  />
);
