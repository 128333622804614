import React from 'react';
import { LargestHeading } from 'components';
import { BlogBreadcrumb } from 'partials';
import { theme, Box } from 'design-system';
import { withLocation } from 'utils';
import { FeedNav } from 'partials/blog-shared/FeedNav';

const commonHeadingProps = {
  variant: 'smaller',
};

const BaseFeedHeader = ({
  isHome,
  isPaged,
  isTag,
  isAuthor,
  tag,
  author,
  title = 'Blog',
}) => (
  <Box
    is="header"
    px={[theme.gutters[1]]}
    mb={[theme.verticalSpace.mediumLarge, theme.verticalSpace.large]}
  >
    <Box
      display="flex"
      flex="0 0 auto"
      flexDirection="column"
      width="100%"
      lineHeight="1em"
      mt={[
        theme.verticalSpace.large,
        theme.verticalSpace.large,
        theme.verticalSpace.xLarge,
      ]}
      mb={[theme.verticalSpace.large]}
    >
      {(isHome || isPaged) && (
        <LargestHeading
          className="site-title"
          text={title}
          {...commonHeadingProps}
        />
      )}
      {isTag && tag && (
        <>
          <BlogBreadcrumb />
          <LargestHeading
            className="category-title"
            text={tag.name}
            {...commonHeadingProps}
          />
        </>
      )}
      {isAuthor && author && (
        <>
          <BlogBreadcrumb />
          <LargestHeading
            className="author-title"
            text={author.name}
            {...commonHeadingProps}
          />
        </>
      )}
    </Box>
    {(isHome || isTag) && <FeedNav isHome />}
  </Box>
);

export const FeedHeader = withLocation(BaseFeedHeader);
