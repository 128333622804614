import React from 'react';
import { Box, theme, columnWidth } from 'design-system';
import { Spacing } from 'components';
import { withNavState } from 'utils';

export class BaseBlogTemplateWrapper extends React.Component {
  componentDidMount() {
    const { updateNavThemes } = this.props;
    updateNavThemes({
      topBlockTheme: 'default',
      pageTheme: 'light',
    });
  }

  render() {
    const { children, ...props } = this.props;
    return (
      <>
        <Box
          ml={[theme.doubleGutters[0], theme.doubleGutters[0], columnWidth(2)]}
          mr={[
            theme.doubleGutters[0],
            theme.doubleGutters[0],
            theme.doubleGutters[0],
            theme.doubleGutters[1],
          ]}
          {...props}
        >
          {/* topnav-offset spacing */}
          <Spacing
            display={['block', 'block', 'none']}
            height="topNavSpacing"
          />
          <Spacing height="xLarge" />
          {children}
        </Box>
      </>
    );
  }
}

export const BlogTemplateWrapper = withNavState(BaseBlogTemplateWrapper);
