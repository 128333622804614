/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
import React, { useState } from 'react';
import _isEqual from 'lodash/isEqual';
import { Box, Button } from 'design-system';
import {
  getFeedCriteriaFromUrl,
  withLocation,
  handleNextCriteria,
  stitchNewFilterState,
  getSiteSettings,
} from 'utils';

const generateNextFilterStateValue = ({ slug, filterState }) => {
  const { value: filterStateValue } = filterState;
  if (slug === 'all') {
    return [];
  }
  return filterStateValue.includes(slug)
    ? filterStateValue.filter((contentType) => contentType !== slug)
    : [...filterStateValue, slug];
};

const onCriteriaChangeEpic = (
  [prevCriteriaState, setCriteriaState],
  locationAndNavigate,
) => (filterState) => (slug) => () => {
  const nextFilterStateValue = generateNextFilterStateValue({
    filterState,
    slug,
  });

  const nextFilterState = { ...filterState, value: nextFilterStateValue };

  const nextCriteriaState = stitchNewFilterState(
    prevCriteriaState,
    nextFilterState,
  );

  if (_isEqual(prevCriteriaState, nextCriteriaState)) {
    return;
  }

  handleNextCriteria({
    setCriteriaState,
    locationAndNavigate,
    nextCriteriaState,
  })();
};

const FeedNavHorizontalMenu = ({ navItems, onChange }) => (
  <Box
    display={['flex']}
    alignItems="center"
    overflowY="hidden"
    letterSpacing="0.4px"
    whiteSpace="nowrap"
    mx="-12px"
  >
    <ul className="nav" role="menu">
      {navItems.map(({ label, url, isCurrent, slug }) => {
        return (
          <li
            key={`blog-flat-nav-tag-${url}`}
            className={`nav-all-posts ${isCurrent ? 'nav-current' : ''}`}
            role="menuitem"
          >
            <Button
              fontFamily={isCurrent ? 'regular' : 'light'}
              colors="lightBackgroundNavItem"
              className={`${isCurrent ? 'active' : ''}`}
              onClick={onChange(slug)}
              type="button"
              py="10px"
              px="12px"
            >
              {label}
            </Button>
          </li>
        );
      })}
    </ul>
  </Box>
);

const contentTypeItems = [
  {
    label: 'All Types',
    slug: 'all',
  },
  {
    label: 'Blogs',
    slug: 'blog',
  },
  {
    label: 'Podcasts',
    slug: 'podcast',
  },
];

const generateTagItems = () => {
  const siteSettings = getSiteSettings();
  const { feedTags } = siteSettings.props;
  return [{ label: 'All Tags', slug: 'all' }].concat(
    feedTags.map(({ props: { title, shortTitle, slug } }) => ({
      label: shortTitle || title,
      slug,
    })),
  );
};

const filterItemsMapper = (filter) => (item) => ({
  ...item,
  isCurrent:
    (item.slug === 'all' && filter.value.length === 0) ||
    filter.value.includes(item.slug),
});

const BaseFeedNav = ({
  isHome,
  isTag,
  isAuthor,
  location: locationAndNavigate,
}) => {
  const tagItems = generateTagItems();

  const feedCriteria = getFeedCriteriaFromUrl(
    locationAndNavigate.location.search,
  );
  const feedCriteriaHook = useState(feedCriteria);
  const onCriteriaChange = onCriteriaChangeEpic(
    feedCriteriaHook,
    locationAndNavigate,
  );
  const { filters } = feedCriteria;
  const tagFilter = filters.find(({ type }) => type === 'byAllTags');
  const contentTypeFilter = filters.find(({ type }) => type === 'contentTypes');
  return (
    <nav className="site-nav">
      {isHome && (
        <>
          <FeedNavHorizontalMenu
            navItems={tagItems.map(filterItemsMapper(tagFilter))}
            onChange={onCriteriaChange(tagFilter)}
          />
          <FeedNavHorizontalMenu
            navItems={contentTypeItems.map(
              filterItemsMapper(contentTypeFilter),
            )}
            onChange={onCriteriaChange(contentTypeFilter)}
          />
        </>
      )}
      {(isTag || isAuthor) && <p className="site-nav-total">totals go here</p>}
    </nav>
  );
};

export const FeedNav = withLocation(BaseFeedNav);
